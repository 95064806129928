/* eslint-disable jsx-a11y/anchor-has-content */
import React, { useState } from "react";
import { FiChevronRight, FiMenu, FiAlignLeft } from "react-icons/fi";
import {
  CDBSidebar,
  CDBSidebarContent,
  CDBSidebarHeader,
  CDBSidebarMenu,
  CDBSidebarMenuItem,
} from "cdbreact";
import { NavLink } from "react-router-dom";
// import Icon1 from '../../assets/ico_menu_home.svg';
import hambergurClose from "./../../assets/ico_menu_hambergur_close.svg";
import hambergurOpen from "./../../assets/ico_menu_hambergur.svg";
import "./Sidebar.scss";
 

const Sidebar = () => {
  const [isOpen, setIsOpen] = useState(false);
 
  const navLinkStyles = {
    color: "#fff", // Deep blue for text color
    textDecoration: "none",
  };
 
  const handleToggle = () => {
    setIsOpen(!isOpen);
  };
  const activeNavLinkStyles = {
    backgroundColor: "transpaent", // Optional: Change background for active link
    color: "#ffb600", // Different color for active state if needed
  };
 
  return (
    <>
      <div className="sidebarPanel">
        <CDBSidebar textColor="#fff" backgroundColor="transparent">
          <CDBSidebarHeader
            prefix={
              isOpen ? (
                // <FiChevronRight
                //   onClick={handleToggle}
                //   style={{ cursor: "pointer" }}
                // />
                <span className="hambergurTag" onClick={handleToggle}>
                  <img
                    src={hambergurOpen}
                    alt="hambergur"
                    className="hambergurIcon"
                  />
                </span>
              ) : (
                // <FiAlignLeft
                //   onClick={handleToggle}
                //   style={{ cursor: "pointer" }}
                // />
                <span className="hambergurTag" onClick={handleToggle}>
                  <img
                    src={hambergurClose}
                    alt="hambergur"
                    className="hambergurIcon"
                  />
                </span>
              )
            }
          ></CDBSidebarHeader>

          <CDBSidebarContent className="sidebar-content">
            <CDBSidebarMenu>
              <NavLink
                exact
                to="/"
                activeClassName="activeClicked"
                style={navLinkStyles}
                activeStyle={activeNavLinkStyles}
              >
                <CDBSidebarMenuItem icon="HomeIcon">
                  <span className="menuText">Home</span>
                </CDBSidebarMenuItem>
              </NavLink>
              <NavLink
                exact
                to="/livechat"
                activeClassName="activeClicked"
                style={navLinkStyles}
                activeStyle={activeNavLinkStyles}
              >
                <CDBSidebarMenuItem icon="ChatIcon">
                  <span className="menuText">Discovery</span>
                </CDBSidebarMenuItem>
              </NavLink>
              <NavLink
                exact
                to="/recommendation"
                activeClassName="activeClicked"
                style={navLinkStyles}
                activeStyle={activeNavLinkStyles}
              >
                <CDBSidebarMenuItem icon="RecommendationIcon">
                  <span className="menuText">Recommendation</span>
                </CDBSidebarMenuItem>
              </NavLink>
              <NavLink
                exact
                to="/analyticalinsight"
                activeClassName="activeClicked"
                style={navLinkStyles}
                activeStyle={activeNavLinkStyles}
              >
                <CDBSidebarMenuItem icon="InsightIcon">
                  <span className="menuText">Analytical Insight</span>
                </CDBSidebarMenuItem>
              </NavLink>
              
          
              {/* <NavLink
                exact
                to="/revenue"
                activeClassName="activeClicked"
                style={navLinkStyles}
                activeStyle={activeNavLinkStyles}
              >
                <CDBSidebarMenuItem icon="chart-line">
                  Revenue
                </CDBSidebarMenuItem>
              </NavLink> */}
              <span className="linebar">&nbsp;</span>
              <NavLink
                exact
                to="/client"
                activeClassName="activeClicked"
                style={navLinkStyles}
                activeStyle={activeNavLinkStyles}
              >
                <CDBSidebarMenuItem icon="ClientIcon">
                  <span className="menuText">Clients</span>
                </CDBSidebarMenuItem>
              </NavLink>
              <NavLink
                exact
                to="/project"
                activeClassName="activeClicked"
                style={navLinkStyles}
                activeStyle={activeNavLinkStyles}
              >
                <CDBSidebarMenuItem icon="ProjectIcon">
                  <span className="menuText">Projects</span>
                </CDBSidebarMenuItem>
              </NavLink>
              {/* <NavLink
                exact
                to="/task"
                activeClassName="activeClicked"
                style={navLinkStyles}
                activeStyle={activeNavLinkStyles}
              >
                <CDBSidebarMenuItem icon="clipboard">Task</CDBSidebarMenuItem>
              </NavLink> */}
              <NavLink
                exact
                to="/summary"
                activeClassName="activeClicked"
                style={navLinkStyles}
                activeStyle={activeNavLinkStyles}
              >
                <CDBSidebarMenuItem icon="SummaryIcon">
                  <span className="menuText">Portfolio Summary</span>
                </CDBSidebarMenuItem>
              </NavLink>
              <span className="linebar">&nbsp;</span>
              <NavLink
                exact
                to="/contracts"
                activeClassName="activeClicked"
                style={navLinkStyles}
                activeStyle={activeNavLinkStyles}
              >
                <CDBSidebarMenuItem icon="DocumentIcon">
                  <span className="menuText">Contract Documents</span>
                </CDBSidebarMenuItem>
              </NavLink>
              <NavLink
                exact
                to="/sources"
                activeClassName="activeClicked"
                style={navLinkStyles}
                activeStyle={activeNavLinkStyles}
              >
                <CDBSidebarMenuItem icon="CodeIcon">
                  <span className="menuText">Source Code</span>
                </CDBSidebarMenuItem>
              </NavLink>
            </CDBSidebarMenu>
          </CDBSidebarContent>
        </CDBSidebar>

        <div className="sidebarBtm">
          <span className="copyRightText">Powered By PwC</span>
        </div>
      </div>
 
    </>
  );
};

export default Sidebar;
