import React from "react";
import { Worker, Viewer } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
const PdfViewer = ({ pdfUrl }) => {
  console.log('pdfUrl', pdfUrl)
  return (
    <div style={{height: "100%", overflowY: 'auto' }}>
      <Worker workerUrl={`https://cdnjs.cloudflare.com/ajax/libs/pdf.js/3.11.174/pdf.worker.min.js`}>
        <Viewer fileUrl={pdfUrl} style={{width: '100%'}} />
      </Worker>
    </div>
  );
};

export default PdfViewer;
