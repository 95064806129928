import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { FaSearch } from "react-icons/fa";
import "./Trace.scss";
import { fatchedGetRequest, getURL } from "../../ApiService";
import Modal from "../../common/Modal/Modal";
import PdfViewer from "../chatbot/PdfViewer";

const Trace = ({ selectedChatbot, activeIndexValue, title }) => {
  const [activeIndex, setActiveIndex] = useState(null);
  const [titlevalue, setTitlevalue] = useState("");
  const [pdfUrl, setPdfUrl] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleToggle = (index) => {
    setActiveIndex((prevIndex) => (prevIndex === index ? null : index));
  };
  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const pdfPathGen = async (pdfpath) => {
    try {
      const title = pdfpath.split("/");
      setTitlevalue(title[title.length - 1]);
      const response = await fatchedGetRequest(
        `${getURL.pdfPathApi}?s3Uri=${pdfpath}`
      );
      console.log("response.url", response.url);
      if (pdfpath?.toLowerCase().includes(".xlsx")) {
        window.open(response.url, "_blank");
      } else if (pdfpath?.toLowerCase().includes(".pdf")) {
        setPdfUrl(response.url);
        openModal();
      } else {
        window.open(response.url, "_blank");
      }
    } catch (error) {
    } finally {
    }
  };
  useEffect(() => {
    console.log('activeIndexValue', activeIndexValue)
    setActiveIndex(activeIndexValue)
  }, [activeIndexValue]);
  return (
    <>
      <div className="tracePanelInner">
        {selectedChatbot?.length > 0 ? (
          <>
            <h5 className="titleText">{title}</h5>
            <div className="traceList">
              <ul>
                {selectedChatbot.map((item, index) => {
                  const tag2 =
                    item?.trace?.orchestrationTrace?.modelInvocationOutput;

                  const tag3 = item?.trace?.orchestrationTrace?.rationale;

                  const tag5 = item?.trace?.orchestrationTrace?.observation;

                  if (tag2?.rawResponse?.content) {
                    const textData = JSON.parse(tag2?.rawResponse?.content);
                    if (textData?.stop_sequence === null) {
                      return (
                        <li key={index}>
                          <h6
                            className="accTitle"
                            onClick={() => handleToggle(index)}
                          >
                            Thought Process
                          </h6>
                          {activeIndex === index && (
                            <>
                              {textData?.content.map((sub, ii) => {
                                if (sub?.text) {
                                  return (
                                    <div key={ii}>
                                      {sub?.text && <p>{sub?.text}</p>}
                                    </div>
                                  );
                                }
                              })}
                            </>
                          )}
                        </li>
                      );
                    }
                  } else if (tag3?.text) {
                    return (
                      <li key={index}>
                        <h6
                          className="accTitle"
                          onClick={() => handleToggle(index)}
                        >
                          Indicative Actions
                        </h6>
                        {activeIndex === index && (
                          <>
                            <p>{tag3?.text}</p>
                          </>
                        )}
                      </li>
                    );
                  } else if (tag5?.knowledgeBaseLookupOutput) {
                    return (
                      <li key={index}>
                        <div className="urlList">
                          <div className="sourcesList">
                            <h6
                              className="accTitle colorText1"
                              onClick={() => handleToggle(index)}
                            >
                              {" "}
                              Most Relevant Evidences
                            </h6>
                            {activeIndex === index && (
                              <>
                                {tag5?.knowledgeBaseLookupOutput?.retrievedReferences.map(
                                  (sub, ii) => {
                           
                                    return (
                                      <div key={ii} className="qutoteitem mb-3">
                                        {sub?.content && (
                                          <p className="textWrap">
                                            {sub?.content?.text}
                                          </p>
                                        )}
                                        <div
                                          className="linkItem"
                                          onClick={() => {
                                            pdfPathGen(
                                              sub?.location?.s3Location?.uri
                                            );
                                          }}
                                        >
                                          {sub?.location && (
                                            <>
                                              {sub?.location?.s3Location?.uri}
                                            </>
                                          )}
                                        </div>
                                      </div>
                                    );
                                  }
                                )}
                              </>
                            )}
                          </div>
                        </div>
                      </li>
                    );
                  }
                })}
              </ul>
            </div>
          </>
        ) : (
          <>
            <div className="d-flex justify-content-center pt-5 align-items-center">
              <span className="h5">
                The trace report will be generated once I ask a question
              </span>
            </div>
          </>
        )}
      </div>
      <Modal isOpen={isModalOpen} onClose={closeModal}>
        <div>
          <div className="popupTitleBlock mb-3">
            <h4>{titlevalue.split("/")}</h4>
          </div>
          <div className="pdfViewerblock">
            <PdfViewer pdfUrl={pdfUrl} />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default Trace;
