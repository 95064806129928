import React from "react";
import pwclogo from "../../assets/pwc-logo-dark.svg";
import "./Footer.scss";

const Footer = () => {
  return (
    <>
       <footer className="footer">
        <div className="footerInner">
          <span className="copyRightText">Powered By PwC</span>
          <span className="footerText">The model may provide inaccurate or incomplete information, Please verify the information provided</span>
        </div>
       </footer>
    </>
  );
};

export default Footer;
