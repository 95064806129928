// const BASE_URL = "https://mrma-genai.us-east-1.elasticbeanstalk.com";
const BASE_URL = process.env.REACT_APP_API_URL || "http://127.0.0.1:5000";
// const BASE_URL = "https://mrmabackend.com";
// const BASE_URL = "http://127.0.0.1:5000";
const apiURL = BASE_URL + "/api/";

export const getURL = {
  getAllEmployeeDetails: apiURL + "Projectv1",  //projects               
  getRevenueDetails: apiURL + "RevenueDetails",
  getTaskDetails: apiURL + "TaskDetails",
  getClientDetails: apiURL + "ClientDetails",
  getProjectDetails: apiURL + "ProjectDetails",
  getsearchdata : apiURL + "search",
  getTaskSearch : apiURL + "TaskSearch",
  getProjectSearch : apiURL + "ProjectSearch",
  getClientSearch : apiURL + "ClientSearch",
  getRevenueSearch : apiURL + "RevenueSearch",
  ChatBotApi: apiURL + "fetch_agent_response", 
  pdfPathApi: BASE_URL + "/get_pdf_url", 
  getDocumentList: BASE_URL + "/list_documents", 
  getSummaryResponse: apiURL + "/fetch_summary_response", 
  getRecommendation: apiURL + "fetch_recommendation_response", 

};

export const fatchedGetRequest = async (url) => {
  try {
    const response = await fetch(url, {
      method: "GET",
    });
    if (!response.ok) {
      return await response.json();
    }
    return await response.json();
  } catch (e) {
    throw new Error(`Error fetchin data: ${e.message}`);
  }
};

export const fetchedGetRequestWithBody = async (url, body) => {
  try {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    });
    
    if (!response.ok) {
      throw new Error(`Request failed with status ${response.status}`);
    }

    return await response.json();
  } catch (e) {
    throw new Error(`Error fetching data: ${e.message}`);
  }
};