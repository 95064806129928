import React from "react";
import pwclogo from "../../assets/pwc-logo-dark.svg";
import logoutIcon from "../../assets/ico_logout_header.svg";
import "./Header.scss";

const Header = () => {
  return (
    <>
      <div className="header">
        <div className="headerIn">
          <div className="headerLeft">
            <div className="headerlogo">
              <img
                src={pwclogo} // Add your logo URL here
                alt="Pwc Logo"
              />
            </div>
            <div className="headerName">
              MRMA
            </div>
            <div className="headerNameSub">
              Migration Risk Mitigation Assistant
            </div>
          </div>
          <div className="headerRight">
            <span className="logout">
              <img src={logoutIcon} alt="logout" />
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
