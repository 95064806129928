import React, { useState, useEffect } from "react";
import botIcon from "../../assets/red_chat.png";
import profileImg from "../../assets/user_img.png";
import "./chatbot.scss";
import {
  fatchedGetRequest,
  fetchedGetRequestWithBody,
  getURL,
} from "../../ApiService";
import PdfViewer from "./PdfViewer";
import Modal from "../../common/Modal/Modal";
import Trace from "../Trace/Trace";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";

const ChatBot = () => {
  // const dispatch = useDispatch();
  const [chatBotHintsStatus, setChatBotHintsStatus] = useState(true);
  const [chatBotHintsText, setChatBotHintsText] = useState(true);

  const [conversation, setConversation] = useState([]);
  const [typeInActive, setTypeInActive] = useState(false);
  const [outerMsgShow, setOuterMsgShow] = useState(true);
  const [selectedChatbot, setSelectedChatbot] = useState([]);
  const [sessionId, setSessionId] = useState("");
 
  const [hintsData, setHintsData] = useState([
    "List a few clients where columns like VNP or ERAS are not NULL.",
    'What does the contract and application code says about loyalty program for "Axis Bank Limited"?',
    'Compare "Axis Bank Limited" and "Alkem" on loyalty program mentions in contract documents and application codebase.',
  ]);
  const [hintsList, setHintsList] = useState(hintsData);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const [titlevalue, setTitlevalue] = useState("");

  const [data, setdata] = useState({
    send: "",
    recieve: "",
  });
  const [activeIndex, setActiveIndex] = useState(null);
  const outerMsgInfo = JSON.parse(localStorage.getItem("outerMsg"));

  const generateSessionId = () =>
    `session_${Math.random().toString(36).substr(2, 9)}`;
  const isDataExpired = (storedTimestamp) => {
    const currentTime = new Date();
    const storedTime = new Date(storedTimestamp);
    const timeDifference = currentTime - storedTime; // in milliseconds
    const sevenDaysInMilliseconds = 7 * 24 * 60 * 60 * 1000;
    return timeDifference > sevenDaysInMilliseconds;
  };
  // Load data from localStorage on component mount
  useEffect(() => {
    const storedData = JSON.parse(localStorage.getItem("chatData"));
    if (storedData && !isDataExpired(storedData.timestamp)) {
      setConversation(storedData.conversation || []);
      setSessionId(storedData.sessionId || generateSessionId());
      if (storedData.conversation.length > 0) {
        setChatBotHintsStatus(true);
        setChatBotHintsText(false);
        const item =
          storedData?.conversation[storedData?.conversation.length - 1];

        setSelectedId(item.id);
        setSelectedChatbot(item.trace);
      }
    } else {
      // If the data is expired or doesn't exist, reset everything
      const newSessionId = generateSessionId();
      setSessionId(newSessionId);
      setConversation([]);
      localStorage.removeItem("chatData"); // Clear expired data
    }
  }, []);

  // Save session ID and conversation to localStorage whenever they update
  useEffect(() => {
    if (sessionId) {
      const chatData = {
        sessionId,
        conversation,
        timestamp: new Date().toISOString(), // Save current timestamp
      };
      localStorage.setItem("chatData", JSON.stringify(chatData));
    }
  }, [sessionId, conversation]);

  const getRelativeTime = (timestamp) => {
    const now = new Date();
    const diff = now - new Date(timestamp);
    const days = Math.floor(diff / (1000 * 60 * 60 * 24)); // days
    const hours = Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)); // hours
    const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60)); // minutes

    if (days > 0) {
      return `${days} day${days > 1 ? "s" : ""} ago, ${new Date(
        timestamp
      ).toLocaleTimeString("en-US")}`;
    } else if (hours > 0) {
      return `${hours} hour${hours > 1 ? "s" : ""} ago, ${new Date(
        timestamp
      ).toLocaleTimeString("en-US")}`;
    } else if (minutes > 0) {
      return `${minutes} minute${minutes > 1 ? "s" : ""} ago, ${new Date(
        timestamp
      ).toLocaleTimeString("en-US")}`;
    } else {
      return `Just now, ${new Date(timestamp).toLocaleTimeString("en-US")}`;
    }
  };
  const formatDate = (date) => {
    const options = { year: "numeric", month: "short", day: "2-digit" };
    return new Intl.DateTimeFormat("en-US", options).format(date);
  };

 
  const fetchChatbotData = async (obj) => {
    try {
      const response = await fetchedGetRequestWithBody(getURL.ChatBotApi, obj);
      if (response) {
        const newBotMessage = {
          type: "bot",
          content: response.chunk,
          id: Math.random().toString(36).substr(2, 9),
          trace: response.trace,
          timestamp: new Date().toISOString(),
        };
        setConversation((prev) => [...prev, newBotMessage]);

        const divs = document.querySelectorAll(".selectedBubble");
        divs.forEach((div) => {
          div.classList.remove("selectedBubble");
        });
        setSelectedId(newBotMessage.id);
        setSelectedChatbot(newBotMessage.trace);
        setHintsList(hintsData);
        setChatBotHintsStatus(true);
        setChatBotHintsText(false);
        setTypeInActive(false);
        setActiveIndex(null);
        setTimeout(() => {
          scrollToBottom(100);
        }, 100);
      }
    } catch (error) {
      console.error("Error fetching Data", error.message);
      setConversation([]);
      setSessionId(generateSessionId());
      localStorage.removeItem("chatData");
    } finally {
    }
  };

  const scrollToBottom = (e) => {
    const element = document.getElementById("scrollID");
    if (element) {
      element.scrollTop = element?.scrollHeight + element?.scrollHeight;
    }
  };

  useEffect(() => {
    if (outerMsgInfo === true) {
      setOuterMsgShow(false);
    }
  }, [typeInActive, outerMsgShow]);

  const typeInChange = (e) => {
    setdata((prev) => ({ ...prev, send: e.target.value }));
    setTimeout(() => {
      scrollToBottom(100);
    }, 100);
  };
  const handleKeyDown = (event) => {
    setdata((prev) => ({ ...prev, send: event.target.value }));
    if (event.key === "Enter") {
      sendMesage();
      setTimeout(() => {
        scrollToBottom(100);
      }, 100);
    }
  };

  const chatClick = (item) => {
    setSelectedId(item.id);
    setSelectedChatbot(item.trace);
    setActiveIndex(0);
  };
 
  

  const clearChat = () => {
    setSelectedId(null);
    setSelectedChatbot([]);
    setChatBotHintsStatus(true);
    setChatBotHintsText(true);
    setHintsList(hintsData);
    setConversation([]);
    setSessionId(generateSessionId());
    localStorage.removeItem("chatData");
  };

 

  const sendMesage = () => {
    console.log("data", data);
    if (data.send !== "") {
      const userMessage = {
        type: "user",
        content: data.send,
        timestamp: new Date().toISOString(),
      };

      setConversation((prev) => [...prev, userMessage]);

      const obj = {
        sessionId: sessionId,
        inputText: data.send,
      };
      fetchChatbotData(obj);
      setTypeInActive(true);
      setdata((prev) => ({
        ...prev,
        send: "",
      }));
      setChatBotHintsStatus(false);
      setChatBotHintsText(false);
    }
    setTimeout(() => {
      scrollToBottom(100);
    }, 100);
  };

  const hintsItemPress = (item) => {
    setChatBotHintsStatus(false);
    setChatBotHintsText(false);
    setdata((prev) => ({ ...prev, send: item }));
  };

  useEffect(() => {
    if (data && chatBotHintsStatus === false && hintsList.length > 0) {
      sendMesage();
      setHintsList([]);
    }
  }, [chatBotHintsStatus, data, data.send, hintsList.length]);
 

  return (
    <>
      <div className="chatbotPanel">
        <div className={"chatbotBox "}>
          <div className="chatbotHeading">
            {/* <h4 className="chatbotTitle">MRMA</h4> */}
            <div className="d-flex align-items-center gap-3">
              <span
                className="clearChat"
                onClick={() => {
                  clearChat();
                }}
              >
                Clear Chat
              </span>
            </div>
          </div>
          <div className="chatbotMain">
            <div className="chatbotList" id="scrollID">
              <div className="chatbotListInner" id="listId">
          
                {conversation.map((message, index) => {
                  if (message.type === "user") {
                    return (
                      <div
                        className="cahtbotIterations userResponse"
                        key={index}
                      >
                        <div className="cahtbotInner flex-wrap">
                          <div className="chatBotBubble">
                            <div className="chatBotContent">
                              <p> {message.content}</p>
                            </div>
                          </div>
                          <small className="dateSmall">
                            {message.timestamp === new Date().toISOString()
                              ? `${formatDate(
                                  new Date(message.timestamp)
                                )}, ${new Date(
                                  message.timestamp
                                ).toLocaleTimeString("en-US")}`
                              : getRelativeTime(message.timestamp)}
                          </small>
                        </div>
                      </div>
                    );
                  } else {
                    return (
                      <div
                        className={
                          message.id === selectedId
                            ? "selectedBubble cahtbotIterations botResponse"
                            : "cahtbotIterations botResponse"
                        }
                        onClick={() => chatClick(message)}
                        id={message.id}
                        key={index}
                      >
                        <div className="cahtbotInner w-100">
                          <div className="chatBotImg">
                            <img src={botIcon} alt="icon" />
                          </div>
                          <div className="chatBotBubble">
                            <div
                              className={
                                "chatBotContent " +
                                (message?.Result ===
                                  "Again generate the chat!!" && "chatBotError")
                              }
                            >
                              {/* <p>{message.content}</p> */}
                              <SyntaxHighlighter wrapLongLines={true} customStyle={{background:'none',whiteSpace: 'pre-wrap', wordWrap: 'break-word'}}>
                                {message.content}
                              </SyntaxHighlighter>
                            </div>
                          </div>
                          <small className="dateSmall">
                            {message.timestamp === new Date().toISOString()
                              ? `${formatDate(
                                  new Date(message.timestamp)
                                )}, ${new Date(
                                  message.timestamp
                                ).toLocaleTimeString("en-US")}`
                              : getRelativeTime(message.timestamp)}
                          </small>
                        </div>
                      </div>
                    );
                  }
                })}

                {typeInActive && (
                  <div className="typeInSec">
                    <div className="typing">
                      <span></span>
                      <span></span>
                      <span></span>
                    </div>
                  </div>
                )}
              </div>
            </div>
            {chatBotHintsStatus && (
              <div className="hitnsPanel flex-column">
                {chatBotHintsText && (
                  <div className="d-flex flex-column align-items-center mb-4">
                    <div className="hintsTitle">
                      Chat with Data, Metadata, Contracts, and Legacy Code
                    </div>
                    <div className="hintsSubTitle h5">
                      Ask anything or try an example
                    </div>
                  </div>
                )}
                <div className="d-flex justify-contnet-center">
                  <>
                    {hintsList.map((item, index) => {
                      return (
                        <div
                          className="hintItem"
                          key={index}
                          onClick={() => hintsItemPress(item)}
                        >
                          {item}
                        </div>
                      );
                    })}
                  </>
                </div>
              </div>
            )}
            <div className="cahtBotTypeingPanel">
              <div className="typeingField">
                <input
                  onChange={(e) => typeInChange(e)}
                  onKeyDown={(e) => handleKeyDown(e)}
                  value={data.send}
                  className="typeingInput"
                  placeholder="Type your new question"
                />
              </div>
              <div className="typeingActions">
                <span className="chatbotSend" onClick={() => sendMesage()}>
                  {/* <i className="sendIcon"></i> */}
                  <span className="sendIcon"></span>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="tracePanel">
        <Trace 
          selectedChatbot={selectedChatbot}
          activeIndexValue={activeIndex}
          title='Reasoning and Execution log'
        />
        </div>
      </div>
 
    </>
  );
};
export default ChatBot;
