import React, { useState, useEffect } from "react";
import { fatchedGetRequest, getURL } from "../ApiService";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import "../Style.css";
import Search from "../common/Search/Search";
import { Paginator } from "primereact/paginator";
import Modal from "../common/Modal/Modal";
import PdfViewer from "./chatbot/PdfViewer";

const Contracts = () => {
  const [tableData, setTableData] = useState([]);
  const [tableDataOrginal, setTableDataOrginal] = useState([]);
  const [scrollHeight, setScrollHeight] = useState("");

  const [loadingTable, setLoadingTable] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [totalRow, setTotalRecords] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [pdfUrl, setPdfUrl] = useState("");
  const [rowDataItem, setRowDataItem] = useState([]);

  useEffect(() => {
    const scrollHeightValue = window.innerHeight - 240;

    setScrollHeight(scrollHeightValue + "px");
  }, []);

  const columns = [
    // { field: "ClientID", header: "Client ID" },
    { field: "contractName", header: "Contract Name" },
    { field: "lastUpdateDate", header: "Last updated Date and Time" },
    // { field: "filePath", header: "File Path" },
  ];

  const fetchEmpData = async () => {
    try {
      // setLoading(true)
      setLoadingTable(true);
      const responseType = await fatchedGetRequest(
        `${getURL.getDocumentList}?bucketName=migration-risk-contract-docs`
      );

      if (responseType.files) {
        // const allowedExtensions = [".pdf", ".csv", ".xlsx"]; 
        const allowedExtensions = [".pdf" ]; 
        const filteredFiles = filterFiles(
          responseType.files,
          allowedExtensions
        );
        console.log("filteredFiles", filteredFiles);
        setTableDataOrginal(responseType.files);
        setTableData(filteredFiles);
      }
      // setLoading(false)
      setLoadingTable(false);
    } catch (error) {
      console.error("Error fetching Data", error.message);
      // setLoading(false)
      setLoadingTable(false);
    } finally {
      setLoadingTable(false);
    }
  };
  const filterFiles = (files, allowedExtensions) => {
    return files
      .filter((file) => {
        // Check if it's a file (not a directory) and has an allowed extension
        return allowedExtensions.some((extension) => file.endsWith(extension));
      })
      .map((file) => {
        const filePath = file; // Use the full path as file path
        const contractName = file.split("/").pop(); // Extract file name from path
        const lastUpdateDate = "2023-03-22 10:20:45";
        return { contractName, filePath, lastUpdateDate };
      });
  };

  useEffect(() => {
    fetchEmpData();
  }, []);

  const onPageChange = (event) => {
    const { first, rows } = event;
    console.log("check", event, first, rows);
    const newPage = Math.floor(first / rows) + 1;
    setCurrentPage(newPage);
    setRowsPerPage(rows);
  };

  const handleRowClick = (e) => {
    console.log("Row clicked:", e.data); // e.data contains the clicked row's data
    // Add any custom logic you'd like here, e.g., navigating or updating the state
    const path = `s3://migration-risk-contract-docs/${e.data.filePath}`;
    pdfPathGen(path);
    setRowDataItem(e.data)
  };
  const pdfPathGen = async (pdfpath) => {
    try {
      const response = await fatchedGetRequest(
        `${getURL.pdfPathApi}?s3Uri=${pdfpath}`
      );
      console.log("response.url", response.url);
      if (pdfpath?.toLowerCase().includes(".xlsx")) {
        window.open(response.url, "_blank");
      } else if (pdfpath?.toLowerCase().includes(".pdf")) {
        setPdfUrl(response.url);
        openModal();
        console.log('rowDataItem', rowDataItem)
      } else {
        window.open(response.url, "_blank");
      }
    } catch (error) {
    } finally {
    }
  };
  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);
  return (
    <>
      <div className="titleBlock">
        <h4>Contract Documents</h4>
      </div>
      <div className="blockPanel">
        <DataTable
          value={tableData}
          tableStyle={{ minWidth: "50rem" }}
          scrollable
          columnResizeMode="expand"
          resizableColumns
          showGridlines
          scrollHeight={scrollHeight}
          loading={loadingTable}
          className="custom-header-color"
          tooltip="Enter your username"
          tooltipOptions={{ position: "left" }}
          onRowClick={handleRowClick}
        >
          {columns.map((col) => (
            <Column key={col.field} field={col.field} header={col.header} />
          ))}
        </DataTable>

        <Paginator
          first={(currentPage - 1) * rowsPerPage}
          rows={rowsPerPage}
          totalRecords={totalRow}
          rowsPerPageOptions={[50, 100, 150]}
          onPageChange={onPageChange}
        />
      </div>
      {/* <Loader show={loading} /> */}
      <Modal isOpen={isModalOpen} onClose={closeModal}>
        <div>
          <div className="popupTitleBlock mb-3">
            <h4>{rowDataItem && rowDataItem?.contractName}</h4>
          </div>
          <div className="pdfViewerblock">
            <PdfViewer pdfUrl={pdfUrl} />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default Contracts;
