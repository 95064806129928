import React, { useState, useEffect } from "react";
import "./Recommendation.scss";
import {
  fatchedGetRequest,
  fetchedGetRequestWithBody,
  getURL,
} from "../../ApiService";
import Spinner from "../../common/Spinner/Spinner";
import SearchableDropdown from "../../common/Search/SearchableDropdown";
import ReactMarkdown from "react-markdown";
import Modal from "../../common/Modal/Modal";
import botIcon from "../../assets/red_chat.png";
import PdfViewer from "../chatbot/PdfViewer";
import Trace from "../Trace/Trace";
function Recommendation() {
  const [inputValue, setInputValue] = useState("");
  const [genTextValue, setGenTextValue] = useState("");
  const [genTraceValue, setGenTraceValue] = useState("");
  const [getClinetData, setGetClinetData] = useState([]);
  const [conversation, setConversation] = useState([]);
  const [sessionId, setSessionId] = useState("");
  const [selectedChatbot, setSelectedChatbot] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [inputFieldShow, setInputFieldShow] = useState(true);
  const [selectedValue, setSelectedValue] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isPdfModalOpen, setIsPdfModalOpen] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const [typeInActive, setTypeInActive] = useState(false);
  const [chatBotHintsStatus, setChatBotHintsStatus] = useState(true);
  const [chatBotHintsText, setChatBotHintsText] = useState(true);
  const [hintsData, setHintsData] = useState([
    "Rewrite the recommendation rationale in details without missing contractual obligations.",
    "Can you add the start date and end of loyalty program in the recommendation summary.",
  ]);
  const [hintsList, setHintsList] = useState(hintsData);
  const [activeIndex, setActiveIndex] = useState(null);
  const [titlevalue, setTitlevalue] = useState("");
  const [pdfUrl, setPdfUrl] = useState("");
  const generateSessionId = () =>
    `session_${Math.random().toString(36).substr(2, 9)}`;
  const isDataExpired = (storedTimestamp) => {
    const currentTime = new Date();
    const storedTime = new Date(storedTimestamp);
    const timeDifference = currentTime - storedTime; // in milliseconds
    const sevenDaysInMilliseconds = 7 * 24 * 60 * 60 * 1000;
    return timeDifference > sevenDaysInMilliseconds;
  };
  // Load data from localStorage on component mount
  useEffect(() => {
    const storedData = JSON.parse(localStorage.getItem("recoData"));
    if (storedData && !isDataExpired(storedData.timestamp)) {
      setConversation(storedData.conversation || []);
      setSessionId(storedData.sessionId || generateSessionId());
      if (storedData.conversation.length > 0) {
        setChatBotHintsStatus(true);
        setChatBotHintsText(false);
        const item =
          storedData?.conversation[storedData?.conversation.length - 1];

        setSelectedId(item.id);
        setSelectedChatbot(item.trace);
        setGenTextValue(item.content);
      }
    } else {
      // If the data is expired or doesn't exist, reset everything
      const newSessionId = generateSessionId();
      setSessionId(newSessionId);
      setConversation([]);
      localStorage.removeItem("recoData"); // Clear expired data
    }
  }, []);
  useEffect(() => {
    if (sessionId) {
      const recoData = {
        sessionId,
        conversation,
        timestamp: new Date().toISOString(), // Save current timestamp
      };
      localStorage.setItem("recoData", JSON.stringify(recoData));
    }
  }, [sessionId, conversation]);
  const getRelativeTime = (timestamp) => {
    const now = new Date();
    const diff = now - new Date(timestamp);
    const days = Math.floor(diff / (1000 * 60 * 60 * 24)); // days
    const hours = Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)); // hours
    const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60)); // minutes

    if (days > 0) {
      return `${days} day${days > 1 ? "s" : ""} ago, ${new Date(
        timestamp
      ).toLocaleTimeString("en-US")}`;
    } else if (hours > 0) {
      return `${hours} hour${hours > 1 ? "s" : ""} ago, ${new Date(
        timestamp
      ).toLocaleTimeString("en-US")}`;
    } else if (minutes > 0) {
      return `${minutes} minute${minutes > 1 ? "s" : ""} ago, ${new Date(
        timestamp
      ).toLocaleTimeString("en-US")}`;
    } else {
      return `Just now, ${new Date(timestamp).toLocaleTimeString("en-US")}`;
    }
  };
  const formatDate = (date) => {
    const options = { year: "numeric", month: "short", day: "2-digit" };
    return new Intl.DateTimeFormat("en-US", options).format(date);
  };
  const scrollToBottom = (e) => {
    const element = document.getElementById("scrollID");
    if (element) {
      element.scrollTop = element?.scrollHeight + element?.scrollHeight;
    }
  };
  const scrollToBottomRecom = (e) => {
    const element = document.getElementById("recommendationId");
    if (element) {
      element.scrollTop = element?.scrollHeight + element?.scrollHeight;
    }
  };
  const typeInChange = (e) => {
    setInputValue(e.target.value);
  };
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      sendMesage();
    }
  };
  const chatClick = (item) => {
    setSelectedId(item.id);
    setSelectedChatbot(item.trace);
    // setActiveIndex(null);
  };
  const sendMesage = () => {
    const userMessage = {
      type: "user",
      content: inputValue,
      timestamp: new Date().toISOString(),
    };

    setConversation((prev) => [...prev, userMessage]);
    fetchSummaryResponse("chat", inputValue);
    setInputValue("");
    setTypeInActive(true);
    setChatBotHintsStatus(false);
    setChatBotHintsText(false);
  };

  const fetchSummaryResponse = async (type, clientName) => {
    try {
      setIsLoading(true);
      const obj = {
        type: type,
        inputText: clientName,
        sessionId: generateSessionId(),
      };
      setGenTextValue("");
      const response = await fetchedGetRequestWithBody(
        getURL.getRecommendation,
        obj
      );
      if (response) {
        setGenTextValue(response.chunk);
        setGenTraceValue(response.trace);
        setSelectedChatbot(response.trace);
        const newBotMessage = {
          type: "bot",
          content: response.chunk,
          id: Math.random().toString(36).substr(2, 9),
          trace: response.trace,
          timestamp: new Date().toISOString(),
          inputText: clientName,
        };
        setConversation((prev) => [...prev, newBotMessage]);
        setTypeInActive(false);
        setChatBotHintsStatus(true);
        setChatBotHintsText(false);
        setTimeout(() => {
          scrollToBottom(100);
          scrollToBottomRecom(100)
        }, 100);
      }
    } catch (error) {
      console.error("Error fetching Data", error.message);
      setIsLoading(false);
    } finally {
      setSelectedValue(null);
      setIsLoading(false);
    }
  };
  useEffect(() => {
    setTimeout(() => {
      scrollToBottom(100);
      scrollToBottomRecom(100)
    }, 100);
  }, [conversation])
  
  const parseContent = (content) => {
    return (
      <div className="rowBox">
        <ReactMarkdown>{content}</ReactMarkdown>
      </div>
    );
  };

  useEffect(() => {
    if (selectedValue) {
      const userMessage = {
        type: "user",
        content: selectedValue.ClientName,
        timestamp: new Date().toISOString(),
      };

      setConversation((prev) => [...prev, userMessage]);
      fetchSummaryResponse("report", selectedValue.ClientName);
      setChatBotHintsStatus(true);
      setChatBotHintsText(false);
    }
  }, [selectedValue]);

  const clientChangeHandle = (event) => {
    console.log("event", event.target.value);
    // setInputValue(event.target.value);
    if (event.target.value === "All") {
      setInputFieldShow(true);
    } else {
      if (event.target.value) {
        fetchSummaryResponse(event.target.value);
        setInputFieldShow(false);
      }
    }
  };

  const clearChat = () => {
    setSelectedValue(null);
    setGenTextValue("");
    setIsLoading(false);
    setSelectedId(null);
    setSelectedChatbot([]);
    setChatBotHintsStatus(true);
    setChatBotHintsText(true);
    setHintsList(hintsData);
    setConversation([]);
    setSessionId(generateSessionId());
    localStorage.removeItem("recoData");
  };
  const hintsItemPress = (item) => {
    console.log("item", item);
    const userMessage = {
      type: "user",
      content: item,
      timestamp: new Date().toISOString(),
    };

    setConversation((prev) => [...prev, userMessage]);
    fetchSummaryResponse("chat", item);
    setChatBotHintsStatus(false);
    setChatBotHintsText(false);
  };
  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);
  return (
    <>
      <div className="blockPanel">
        <div className="recomendationSec">
          <div className="leftBox">
            <div className="chatbotMain">
              <div className="topsearchArea">
                <div className="titleBlock">
                  <h4>Recommendation</h4>
                  <div className="d-flex align-items-center gap-3">
                    <span
                      className="commonButton"
                      onClick={() => {
                        clearChat();
                      }}
                    >
                      Clear Search
                    </span>
                  </div>
                </div>
                <div className="ulText mb-3">
                  <h5>How it works</h5>
                  <ul className="d-flex gap-2 flex-wrap">
                    <li>
                      <h6>Select, Analyze, and Review</h6>
                      Choose a client or all clients to generate a summary of
                      recommendations: "Retain," "Decommission," or "Recommend
                      Alternative."
                    </li>
                    {/* <li>
                      <h6>Validate</h6>
                      Verify recommendations using relevant data, metadata,
                      contracts, and source code.
                    </li> */}
                    <li>
                      <h6>Refine</h6>
                      Adjust recommendations based on additional insights or
                      business needs.
                    </li>
                    {/* <li>
                      <h6>Download</h6>
                      Export the final summary and analysis in PDF or Word
                      format.
                    </li> */}
                  </ul>
                </div>
                <div className="d-flex pb-3 w-100">
                  <div className="selectedBox w-100">
                    <div className="labeltext mb-2">
                      <span className="text1">Step 1: </span>
                      <span className="text2">
                        Select your Client for Recommendation Summary
                      </span>
                    </div>
                    <div className="mb-4">
                      <SearchableDropdown
                        selectedValue={selectedValue}
                        setSelectedValue={setSelectedValue}
                      />
                    </div>
                    {/* <select
                      onChange={(event) => clientChangeHandle(event)}
                      className="typeingInput"
                    >
                      <option value={"All"}>All Clients</option>
                      {getClinetData &&
                        getClinetData.map((item, index) => {
                          return (
                            <option key={index} value={item.ClientName}>
                              {item.ClientName}
                            </option>
                          );
                        })}
                    </select> */}
                    <div className="labeltext mt-3">
                      <span className="text1">Step 2: </span>
                      <span className="text2">
                        Refine the Recommendation Summary
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="chatAreaPan">
                <div className="queryPostPan" id="scrollID">
 
                  <div className="chatbotList" >
                    <div className="chatbotListInner" id="listId">
                      {conversation.map((message, index) => {
                        if (message.type === "user") {
                          return (
                            <div
                              className="cahtbotIterations userResponse"
                              key={index}
                            >
                              <div className="cahtbotInner flex-wrap">
                                <div className="chatBotBubble">
                                  <div className="chatBotContent">
                                    <p> {message.content}</p>
                                  </div>
                                </div>
                                <small className="dateSmall">
                                  {message.timestamp ===
                                  new Date().toISOString()
                                    ? `${formatDate(
                                        new Date(message.timestamp)
                                      )}, ${new Date(
                                        message.timestamp
                                      ).toLocaleTimeString("en-US")}`
                                    : getRelativeTime(message.timestamp)}
                                </small>
                              </div>
                            </div>
                          );
                        }
                        // else {
                        //   return (
                        //     <div
                        //       className={
                        //         message.id === selectedId
                        //           ? "selectedBubble cahtbotIterations botResponse"
                        //           : "cahtbotIterations botResponse"
                        //       }
                        //       id={message.id}
                        //       key={index}
                        //     >
                        //       <div className="cahtbotInner w-100 flex-wrap">
                        //         <div className="chatBotImg">
                        //           <img src={botIcon} alt="icon" />
                        //         </div>
                        //         <div className="chatBotBubble">
                        //           <div
                        //             className={
                        //               "chatBotContent " +
                        //               (message?.Result ===
                        //                 "Again generate the chat!!" &&
                        //                 "chatBotError")
                        //             }
                        //           >
                        //             <p>{message.trace}</p>
                        //           </div>
                        //         </div>
                        //         <small className="dateSmall">
                        //           {message.timestamp ===
                        //           new Date().toISOString()
                        //             ? `${formatDate(
                        //                 new Date(message.timestamp)
                        //               )}, ${new Date(
                        //                 message.timestamp
                        //               ).toLocaleTimeString("en-US")}`
                        //             : getRelativeTime(message.timestamp)}
                        //         </small>
                        //       </div>
                        //     </div>
                        //   );
                        // }
                      })}

                      {typeInActive && (
                        <div className="typeInSec">
                          <div className="typing">
                            <span></span>
                            <span></span>
                            <span></span>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="selectedBox">
                  {chatBotHintsStatus && (
                    <div className="hitnsPanel flex-column">
                      {chatBotHintsText && (
                        <div className="d-flex flex-column align-items-center mb-4">
                          <div className="hintsTitle">
                            Review and fine tune the Recommendation Summary
                          </div>
                          <div className="hintsSubTitle h5">Try an example</div>
                        </div>
                      )}
                      <div className="d-flex justify-contnet-center">
                        <>
                          {hintsList.map((item, index) => {
                            return (
                              <div
                                className="hintItem"
                                key={index}
                                onClick={() => hintsItemPress(item)}
                              >
                                {item}
                              </div>
                            );
                          })}
                        </>
                      </div>
                    </div>
                  )}
                  <div className="typeingPanel">
                    <div className="typeingField">
                      <input
                        onChange={(e) => typeInChange(e)}
                        onKeyDown={(e) => handleKeyDown(e)}
                        value={inputValue}
                        className="typeingInput"
                        placeholder="Type here"
                      />
                    </div>
                    <div className="typeingActions">
                      <span
                        className={"chatbotSend "}
                        onClick={() => sendMesage()}
                      >
                        {/* <i className="sendIcon"></i> */}
                        <span className="sendIcon"></span>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="rightBox">
            <div className="d-flex justify-content-between ps-5 pe-5">
              <h5 className="titleText">Recommendation Summary and Conversation History</h5>
            </div>
            <div className="recommendationList" >
              {isLoading && (
                <div className="loaderContainer">
                <Spinner />
                </div>
              ) }
                <div className="recmondationPan" id="recommendationId">
                  {conversation.map((message, index) => {
                    if (message.type === "user") {
                    } else {
                      return (
                        <div
                          className={"recomItem"}
                          id={"summary_" + message.id}
                          key={index}
                        >
                          <div className="recomItemInner">
                            <div className="d-flex justify-content-between mb-2 align-items-center recomTop gap-3">
                              <div  >
                                <h6 className="m-0">Q. {message.inputText}</h6>
                                <small className="dateSmall postOn">
                                  Posted on{" "}
                                  {message.timestamp ===
                                  new Date().toISOString()
                                    ? `${formatDate(
                                        new Date(message.timestamp)
                                      )}, ${new Date(
                                        message.timestamp
                                      ).toLocaleTimeString("en-US")}`
                                    : getRelativeTime(message.timestamp)}
                                </small>
                              </div>
                              <div className="buttonPan">
                                <span
                                  className="commonButton"
                                  onClick={() => {
                                    setSelectedChatbot(message.trace);
                                    openModal();
                                  }}
                                >
                                  Show Execution log
                                </span>
                              </div>
                            </div>
                            <div className="recomBubbleBubble">
                              {parseContent(message.content)}
                            </div>
                          </div>
                        </div>
                      );
                    }
                  })}
                </div>
               
              {/* <div>{genTextValue && parseContent(genTextValue)}</div> */}
            </div>
          </div>
        </div>
      </div>
      <Modal isOpen={isModalOpen} onClose={closeModal}>
        <div className="p-5 tracePopupInner">
          <Trace
            selectedChatbot={selectedChatbot}
            activeIndexValue={activeIndex}
            title="Reasoning and Execution log"
          />
        </div>
      </Modal>
    </>
  );
}

export default Recommendation;
