import React, { useEffect } from 'react'
import { Routes, Route, useLocation } from 'react-router-dom';
import Home from './components/Home';
import Revenue from './components/Revenue';
import Client from './components/Client';
import Project from './components/Project';
import Task from './components/Task';
import './styles.scss'
import LiveChat from './components/LiveChat';
import Summary from './components/Summary';
import Footer from './common/Footer/Footer';
import Contracts from './components/Contracts';
import Sources from './components/Sources';
import Recommendation from './components/Recommendation/Recommendation';
import AnalyticalInsight from './components/AnalyticalInsight/AnalyticalInsight';
 

const Main = () => {
  const location = useLocation();
  console.log('location', location)
  useEffect(() => {
    document.body.className = getPageClass();
  }, [location.pathname]);
  const getPageClass = () => {
    switch (location.pathname) {
      case '/':
        return 'homePage';
      case '/livechat':
        return 'chatbotPage';
      case '/recommendation':
        return 'recommendationPage';
      default:
        return 'defaultPage';
    }
  };
  return (
    <main className='main'>
        <div className='mainTop'>
          <Routes>
              <Route path="/" element={<Home />} />
              <Route path="livechat" element={<LiveChat/>}/>
              <Route path="recommendation" element={<Recommendation/>}/>
              <Route path="analyticalinsight" element={<AnalyticalInsight/>}/>
              <Route path="revenue" element={<Revenue />} />
              <Route path="client" element={<Client />} />
              <Route path="project" element={<Project />} />
              <Route path="task" element={<Task/>}/>
              <Route path="summary" element={<Summary/>}/>
              <Route path="contracts" element={<Contracts/>}/>
              <Route path="sources" element={<Sources/>}/>
          </Routes>
        </div>
        <Footer />
    </main>
  )
}
 

export default Main
