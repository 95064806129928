import React, { useEffect, useState } from "react";
import { fatchedGetRequest, getURL } from "../../ApiService";
import CommonChart from "../chatbot/CommonChart";
import Spinner from "../../common/Spinner/Spinner";
import "./AnalyticalInsight.scss";
function AnalyticalInsight() {
 
  const [chartViewData, setChartViewData] = useState([]);
 
  const [isLoading, setIsLoading] = useState(false);
 
  const colors = ["#ffb600", "#5c6670", "#d04a01", "#b8cef9"];
  useEffect(() => {
    fetchSummaryResponse();
  }, [])
  
  const fetchSummaryResponse = async () => {
    try {
      setIsLoading(true);
      const response = await fatchedGetRequest(getURL.getSummaryResponse);
      if (response) {
        setChartViewData(response);
      }
    } catch (error) {
      console.error("Error fetching Data", error.message);
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };
  const transformObject = (input, title, colors, type) => {
    const keys = Object.keys(input[0]); // Get keys from the first object in the data array
    let xKey = null;
    let barKey = null;

    for (const key of keys) {
      const value = input[0][key]; // Check the value of the first entry for each key
      if (typeof value === "string") {
        xKey = key; // Assign xKey if it's a string
      } else if (typeof value === "number") {
        barKey = key; // Assign barKey if it's a number
      }
    }

    if (type === "bar") {
      return {
        xKey: xKey,
        barKey: barKey,
        title: title,
        colors: colors,
      };
    } else if (type === "pie") {
      return {
        xKey: xKey,
        pieKey: barKey,
        title: title,
        colors: colors,
      };
    } else if (type === "donut") {
      return {
        xKey: xKey,
        pieKey: barKey,
        title: title,
        colors: colors,
      };
    } else if (type === "composed") {
      return {
        xKey: xKey,
        barKey: barKey,
        title: title,
        colors: colors,
      };
    }
  };
  return (
    <>
      <div className="titleBlock">
        <h4>Analytical Insight</h4>
      </div>
      <div className="blockPanel">
        <div className="ChartViewerblock flex-wrap chartViewPanel p-0">
          {chartViewData.length > 0 &&
            chartViewData.map((item, index) => {
              return (
                <div key={index} className="chartItem">
                  <CommonChart
                    type={item.type}
                    data={item.data}
                    config={transformObject(
                      item.data,
                      item.title,
                      colors,
                      item.type
                    )}
                    info={item.general_info}
                  />
                </div>
              );
            })}
          {isLoading && <Spinner />}
        </div>
      </div>
    </>
  );
}

export default AnalyticalInsight;
