import React, { useState, useEffect } from "react";
import { fatchedGetRequest, getURL } from "../ApiService";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import "../Style.css";
 
import { Tooltip } from "primereact/tooltip";
import { MonthDisplay } from "../utils/Mothdisplay";
import Search from "../common/Search/Search";
import { Paginator } from 'primereact/paginator';

 
// import Loader from './Loader';
const Summary = () => {
  const [tableData, setTableData] = useState([]);
  const [tableDataOrginal, setTableDataOrginal] = useState([]);
  const [scrollHeight, setScrollHeight] = useState("");
  // const [loading, setLoading] = useState(false);
  const [loadingTable, setLoadingTable] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [totalRow, setTotalRecords] = useState(0);



  useEffect(() => {
    const scrollHeightValue = window.innerHeight - 260;
    // // console.log('height', scrollHeightValue + 'px');
    setScrollHeight(scrollHeightValue + "px");
  }, []);
  //   // Template to display name with a tooltip
  //   const nameTemplate = (rowData) => {
  //     return (
  //         <>
  //             <span id={`nameTooltip${rowData.SubProductDescription}`}>{rowData.SubProductDescription}</span>
  //             <Tooltip target={`#nameTooltip${rowData.id}`} content={rowData.SubProductDescription} />
  //         </>
  //     );
  // };
  const monthName = (monthNumber) => {
    const months = [
        'January', 'February', 'March', 'April', 'May', 'June',
        'July', 'August', 'September', 'October', 'November', 'December'
    ];
    return months[monthNumber - 1];
};
  const columns2 = [
    // { field: "ClientID", header: "Client ID" },
    { field: "ClientName", header: "Client Name" },
    // { field: "ProjectID", header: "Project ID" },
    { field: "ProjectCode", header: "Project Code" },
    { field: "ProjectName", header: "Project Name" },
    { field: "ClientTerritoryFlag", header: "Client Territory Flag" },
    // { field: "TaskCode", header: "Task Code" },
    {
      field: "TaskBlendedUSDPerDayRate",
      header: "Task Blended USD PerDayRate",
    },
    { field: "SubProductName", header: "SubProduct Name" },
    // { field: 'SubProductDescription', header: 'SubProduct Description',},
    {
      field: "SubProductDescription",
      header: "SubProduct Description",
      body: (rowData) => (
        <>
          <div className="columTtext">
            <Tooltip target=".tooltip-target" />
            <span
              tooltip="Enter your username"
              className="tooltip-target"
              data-pr-tooltip={rowData.SubProductDescription}
              data-pr-position="left"
            >
              {rowData.SubProductDescription}
            </span>
          </div>
        </>
      ),
    },
    { field: "Month", header: "Month" },
    { field: "Year", header: "Year" },
    { field: "NetRevenue", header: "Net Revenue ($)"},
  ];

  const columns = [
    { field: "ClientName", header: "Client" },
    { field: "ProjectCode", header: "Project Code" },
    { field: "ProjectName", header: "Project Name", width: '50px' },
    { field: "ClientTerritoryFlag", header: "Territory" },
    { field: "AITHC", header: "AITHC" },
    { field: "ERAS", header: "ERAS" },
    { field: "LPS", header: "LPS" },
    { field: "TP", header: "TP" },
    { field: "VNP", header: "VNP" },
    { field: "Year", header: "Year" },
    { field: "NetRevenue", header: "Net Revenue ($)"},
  ]
  const monthNames = [
    "January", "February", "March", "April", "May", "June", 
    "July", "August", "September", "October", "November", "December"
  ];

 

//   useEffect(() => {
//     fetchEmpData(page, rows);
// }, [page, rows]);

  const fetchEmpData = async (page, rows) => {
    try {
      // setLoading(true)
      setLoadingTable(true);
      const responseType = await fatchedGetRequest(
        `${getURL.getAllEmployeeDetails}?page=${page}&size=${rows}`    
      );
      console.log("fetchSingleProduct", responseType);
      if (responseType.data) {
        const updatedArray = responseType.data
        .map(item => ({
            ...item,
            //  Month: monthNames[parseInt(item.Month) - 1], 
            NetRevenue: item.NetRevenue.toString().replace(/\.?0+$/, ''),
            ERAS: item.ERAS === null ? 'No' : item.ERAS,
            AITHC: item.AITHC === null ? 'No' : item.AITHC,
            LPS: item.LPS === null ? 'No' : item.LPS,
            TP: item.TP === null ? 'No' : item.TP,
            VNP: item.VNP === null ? 'No' : item.VNP,
          }));
          setTotalRecords(responseType.total_count);
          console.log("Total Records:", responseType.total_count);
        setTableData(updatedArray);
        setTableDataOrginal(updatedArray);

      }
      // setLoading(false)
      setLoadingTable(false);
    } catch (error) {
      console.error("Error fetching Data", error.message);
      // setLoading(false)
      setLoadingTable(false);
    } finally {
      setLoadingTable(false);
    }
  };



  useEffect(() => {
    fetchEmpData(currentPage , rowsPerPage);
  }, [currentPage, rowsPerPage]);

  const onPageChange = (event) => {
    const { first, rows } = event; // Destructure first and rows from the event
    console.log("check",event, first, rows)
    const newPage = Math.floor(first / rows) + 1; 
    setCurrentPage(newPage); // Calculate the current page
    setRowsPerPage(rows); // Set the number of rows per page
  };



  // const searchChnages = (event) => {
  //   console.log("event", event);
  //   setTableData(event);
  // };
  // setTimeout(() => {
    //   fetchsearchData(event,page, rows)
    // }, 600);
    
  const searchdata = (event) => {
    console.log("searchdata", event,rowsPerPage);
    
    let timeoutId;
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    timeoutId = setTimeout(() => {
      if(event == []){
        fetchEmpData(currentPage , rowsPerPage);
      }
      else{
        fetchsearchData(event,currentPage, rowsPerPage)
      }
    }, 600);   
  };

  
  const fetchsearchData = async (event,page, rows) => {
    try {
      // setLoading(true)
      setLoadingTable(true);
      console.log("event1",event)
      const resp = await fatchedGetRequest(
        `${getURL.getsearchdata}?q=${event}&page=${page}&size=${rows}`    
      );
      console.log("fetchSearch data:", resp);
      if (resp) {
        const updatedsearchdata = resp
        .map(item => ({
            ...item,
            //  Month: monthNames[parseInt(item.Month) - 1], 
            NetRevenue: item.NetRevenue.toString().replace(/\.?0+$/, ''),
            ERAS: item.ERAS === null ? 'No' : item.ERAS,
            AITHC: item.AITHC === null ? 'No' : item.AITHC,
            LPS: item.LPS === null ? 'No' : item.LPS,
            TP: item.TP === null ? 'No' : item.TP,
            VNP: item.VNP === null ? 'No' : item.VNP,
          }));
          setTotalRecords(updatedsearchdata.length);
         setTableData(updatedsearchdata);
       
        // setTableDataOrginal(updatedsearchdata);
      }
      // setLoading(false)
      setLoadingTable(false);
    } catch (error) {
      console.error("Error fetching Data", error.message);
      // setLoading(false)
      setLoadingTable(false);
    } finally {
      setLoadingTable(false);
    }
  };

  return (
    <>
      {/* Home Page Content */}
  
      <div className="titleBlock">
        <h4>Portfolio Summary</h4>
        <Search
          setsearchdata ={searchdata}
          // orginalData={tableDataOrginal}
          // tableDataArray={tableData}
        />
      </div>
      <div className="blockPanel">
        <DataTable
          key={currentPage}
          value={tableData}
          tableStyle={{ minWidth: "50rem" }}
          // first={(currentPage - 1) * rowsPerPage}
          // rows={rowsPerPage}
          // totalRecords={totalRow}
          scrollable
          // onLazyLoad={fetchEmpData}
          //  scrollHeight="460px"
          columnResizeMode="expand"
          resizableColumns
          showGridlines
          scrollHeight={scrollHeight}
          // paginator
          // rowsPerPageOptions={[50, 100, 150]}
          // paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
          // currentPageReportTemplate="{first} to {last} of {totalRecords}"
          // currentPageReportTemplate={"{first} to {last} of " + `${totalRow}`}
          loading={loadingTable}
          className="custom-header-color"
          // onPage={onPageChange}
          
          
        >
          {columns.map((col) => (
            <Column
              key={col.field}
              field={col.field}
              header={col.header}
              body={col.body}
              style={{ width: col.width }}
            />
          ))}
        </DataTable>
        <Paginator  first={(currentPage - 1) * rowsPerPage} rows={rowsPerPage} totalRecords={totalRow} rowsPerPageOptions={[50, 100, 150]} onPageChange={onPageChange} />
      </div>
      {/* <Loader show={loading} /> */}
    </>
  );
}

export default Summary;
