import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import Header from "./common/Header/Header";
import Sidebar from "./common/Sidebar/Sidebar";
import Main from "./Main";

// import LiveChat from "./components/LiveChat";
function App() {
  return (
    <Router>
      <div style={{height: '100vh', display: 'flex', flexDirection: 'column'}}>
        <Header />
        <div style={{ display: "flex", flexGrow: 1 }}>
          <Sidebar />
          <Main />
        </div>
      </div>
    </Router>
  );
}

export default App;
