import React from "react";
import {
  BarChart,
  Bar,
  LineChart,
  Line,
  PieChart,
  Pie,
  ComposedChart,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Cell,
} from "recharts";

const RADIAN = Math.PI / 180;

// Customized label function for PieChart
const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
  name,
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text
      x={x}
      y={y}
      fill="black"
      textAnchor={x > cx ? "start" : "end"}
      dominantBaseline="central"
      fontSize={16}
      fontWeight={"700"}
      width={60}
      color="#7d7d7d"
    >
      {` ${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

const CommonChart = ({ type, data, config, info }) => {
  const { xKey, yKey, barKey, lineKey, pieKey, title, colors } = config;

  const renderChart = () => {
    if (!data || !data.length) {
      return <p>No data available</p>;
    }

    switch (type) {
      case "bar":
        return (
          //   <BarChart width={800} height={400} data={data}>
          //     <CartesianGrid strokeDasharray="3 3" />
          //     <XAxis dataKey={xKey} />
          //     <YAxis />
          //     <Tooltip />
          //     <Legend />
          //     <Bar dataKey={barKey} fill="#8884d8">
          //       {data.map((entry, index) => (
          //         <Cell key={`cell-${index}`} fill={"#ffb600"} />
          //       ))}
          //     </Bar>
          //   </BarChart>
          <ComposedChart width={800} height={400} data={data}>
            <CartesianGrid stroke="transparent" />
            <XAxis dataKey={xKey} scale="band" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Bar dataKey={barKey} barSize={20} fill="#413ea0">
              {data.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={"#ffb600"} />
              ))}
            </Bar>
            <Line
              type="monotone"
              dataKey={barKey}
              stroke="transparent"
              fill={"#ff0000"}
            />
          </ComposedChart>
        );

      case "line":
        return (
          <LineChart width={800} height={400} data={data}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey={xKey} />
            <YAxis />
            <Tooltip />
            <Legend />
            <Line type="monotone" dataKey={lineKey} stroke="#82ca9d" />
          </LineChart>
        );

      case "pie":
        return (
          <PieChart width={400} height={400}>
            <Tooltip />
     
            <Legend fontSize={14} color="#7d7d7d" height={60} />
            
            <Pie
              data={data}
              dataKey={pieKey}
              nameKey={xKey}
              cx="50%"
              cy="50%"
              outerRadius={150}
              labelLine={false}
              label={renderCustomizedLabel}
              fill="#8884d8"
            >
              {data.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={colors[index % colors.length]}
                />
              ))}
            </Pie>
          </PieChart>
        );
      case "donut":
        return (
          <PieChart width={400} height={400}>
            <Tooltip />
            <Legend fontSize={14} color="#7d7d7d" height={60} />
            <Pie
              data={data}
              dataKey={pieKey}
              nameKey={xKey}
              cx="50%"
              cy="50%"
              outerRadius={150}
              innerRadius={80}
              labelLine={false}
              label={renderCustomizedLabel}
              fill="#8884d8"
            >
              {data.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={colors[index % colors.length]}
                />
              ))}
            </Pie>
          </PieChart>
        );

      case "composed":
        return (
          <ComposedChart width={800} height={400} data={data}>
            <CartesianGrid stroke="transparent" />
            <XAxis dataKey={xKey} scale="band" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Bar dataKey={barKey} barSize={20} fill="#413ea0">
              {data.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={"#ffb600"} />
              ))}
            </Bar>
            <Line
              type="monotone"
              dataKey={barKey}
              stroke="transparent"
              fill={"#ff0000"}
            />
          </ComposedChart>
        );

      default:
        return (
          <p>
            Invalid chart type. Please use "bar", "line", "pie", or "composed".
          </p>
        );
    }
  };

  return (
    <div className="d-flex justify-content-center align-items-center flex-column py-3 ps-2 pe-2">
      <h5 style={{ textAlign: "center" }}>{title}</h5>
      <div className="flex-fill d-flex justify-content-center pb-4">
        {renderChart()}
      </div>
      <p>{info}</p>
    </div>
  );
};

export default CommonChart;
