import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { fatchedGetRequest, getURL } from "../ApiService";
import { FaChartLine, FaUser, FaUsers, FaClipboard } from "react-icons/fa";
import Loader from "./Loader";
import pwclogo from "../assets/pwc_logo.svg";
import HomeIcon from "../assets/ico_menu_home.svg";
import ClientIcon from "../assets/ico_clients.svg";
import ChatIcon from "../assets/yel_chat.png";
import ProjectIcon from "../assets/ico_projects.svg";
import SummaryIcon from "../assets/ico_summary.svg";
import DocumnetIcon from "../assets/ico_documents.svg";
import CodeIcon from "../assets/ico_code.svg";
import logoutIcon from "../assets/ico_logout_dashboard.svg";
import arrowItem from "../assets/arrow.png";
import "../Home.scss";
import "../styles.scss";

// import Loader from './Loader';
const Home = () => {
  const [loadingTable, setLoadingTable] = useState(false);
  const [totalClient, setTotalClientRecords] = useState(0);
  const [totalProject, setTotalProjectRecords] = useState(0);
  const [totalTask, setTotalTaskRecords] = useState(0);
  const [totalRevenue, setTotalRevenue] = useState(0);
  const [totalContact, setTotalContact] = useState(0);
  const [totalSources, setTotalSources] = useState(0);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const handleCardClick = (cardName) => {
    // alert(`You clicked on ${cardName}`);
    navigate(`/${cardName}`);
  };

  useEffect(() => {
    fetchEmpData();
    fetchClientData();
    fetchProjectData();
    fetchTaskData();
    fetchContactData();
  }, []);

  const fetchEmpData = async () => {
    try {
      setLoading(true);
      // setLoadingTable(true);
      const responseType = await fatchedGetRequest(
        `${getURL.getRevenueDetails}?page=1&size=1000`
      );
      console.log("fetchSingleProduct", responseType.data);

      if (responseType.data) {
        // Update the array and also convert the Month and format the NetRevenue
        const updatedArray = responseType.data.map((item) => ({
          ...item,
          NetRevenue: item.NetRevenue.toString().replace(/\.?0+$/, ""),
        }));

        // Calculate the total revenue for the current year
        const currentYear = new Date().getFullYear();

        // Assuming `item.Year` is the year of the revenue record.
        const currentYearData = updatedArray.filter((item) => {
          const yearFromItem = parseInt(item.Year); // Ensure 'Year' is available in the data
          return yearFromItem === currentYear;
        });

        const totalRevenueCurrentYear = currentYearData.reduce(
          (total, item) => {
            const revenue = parseFloat(item.NetRevenue) || 0;
            return total + revenue;
          },
          0
        );
        setTotalRevenue(totalRevenueCurrentYear);
        console.log(
          `Total revenue for the current year (${currentYear}): ${totalRevenueCurrentYear}`
        );
      }
      setLoading(false);
      setLoadingTable(false);
    } catch (error) {
      console.error("Error fetching Data", error.message);
      setLoading(false);
      setLoadingTable(false);
    } finally {
      setLoadingTable(false);
    }
  };

  const fetchClientData = async () => {
    try {
      setLoading(true);
      // setLoadingTable(true);
      const responseType = await fatchedGetRequest(
        `${getURL.getClientDetails}?page=1&size=50`
      );
      console.log("fetchSingleProduct", responseType.data);
      if (responseType.data) {
        setTotalClientRecords(responseType.total_count);
      }
      // setLoadingTable(false);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching Data", error.message);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  const fetchProjectData = async () => {
    try {
      setLoading(true);
      const responseType = await fatchedGetRequest(
        `${getURL.getProjectDetails}?page=1&size=50`
      );
      if (responseType.data) {
        setTotalProjectRecords(responseType.total_count);
      }
      setLoading(false);
    } catch (error) {
      console.error("Error fetching Data", error.message);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  const fetchTaskData = async () => {
    try {
      setLoading(true);
      const responseType = await fatchedGetRequest(
        `${getURL.getTaskDetails}?page=1&size=50`
      );
      console.log("fetchSingleProduct", responseType.data);
      if (responseType.data) {
        setTotalTaskRecords(responseType.total_count);
      }
      setLoading(false);
    } catch (error) {
      console.error("Error fetching Data", error.message);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  const fetchContactData = async () => {
    try {
      // setLoading(true)
      setLoadingTable(true);
      const responseType = await fatchedGetRequest(
        `${getURL.getDocumentList}?bucketName=migration-risk-contract-docs`
      );

      if (responseType.files) {
        const allowedExtensions = [".pdf"];
        const allowedExtensionsCode = [".py"];
        const filteredFiles = filterFiles(
          responseType.files,
          allowedExtensions
        );
        const filteredCode = filterFiles(
          responseType.files,
          allowedExtensionsCode
        );
        setTotalContact(filteredFiles.length);
        setTotalSources(filteredCode.length);
      }
      // setLoading(false)
      setLoadingTable(false);
    } catch (error) {
      console.error("Error fetching Data", error.message);
      // setLoading(false)
      setLoadingTable(false);
    } finally {
      setLoadingTable(false);
    }
  };
  const filterFiles = (files, allowedExtensions) => {
    return files
      .filter((file) => {
        // Check if it's a file (not a directory) and has an allowed extension
        return allowedExtensions.some((extension) => file.endsWith(extension));
      })
      .map((file) => {
        const filePath = file; // Use the full path as file path
        const contractName = file.split("/").pop(); // Extract file name from path
        const lastUpdateDate = "2023-03-22 10:20:45";
        return { contractName, filePath, lastUpdateDate };
      });
  };
  return (
    <>
      <div className="Container">
        <div className="content">
          <div className="left-side">
            <div className="pwclogo">
              <img src={pwclogo} alt="pwc" />
            </div>

            <div className="heading pe-5">
              <span className="firsthead">Welcome to the</span>
              <span className="secoundhead">Migration Risk</span>
              <span className="secoundhead">Mitigation Assistant</span>

              <span className="lasthead">MRMA</span>
              <p className="mb-0 mt-2">
              Your gen AI Solution to Simplify Complexity and Minimize Migration Risks.
              </p>
            </div>
          </div>
          <div className="right-side">
            <div className="col1 firstcol">
              <div
                className="card color3 discoverItem"
                onClick={() => handleCardClick("livechat")}
              >
                <img src={ChatIcon} alt="" className="icon" />
                <span className="textcontent whiteText mt-2">
                  Unlock comprehensive insights and mitigate risk with seamless
                  support from our advanced AI-powered chatbot.
                </span>
                <div className=" d-flex flex-column">
                  <span className="cardtitel2 cardTextSmall">Discover and</span>
                  <span className="cardtitel2 cardTextSmall">
                    Recommendation
                  </span>
                </div>
              </div>
              <div className="arrowBoxes">
                <div className="arrowItem">
                  <img src={arrowItem} alt="img" />
                </div>
                <div className="arrowItem">
                  <img src={arrowItem} alt="img" />
                </div>
                <div className="arrowItem">
                  <img src={arrowItem} alt="img" />
                </div>
              </div>
            </div>
            <div className="col1 secoundcol">
            <div
                className="card color1"
                onClick={() => handleCardClick("client")}
              >
                <img src={ClientIcon} alt="client Logo" className="icon" />
                <span className="textcontent">
                  Explore comprehensive client names across diverse business
                  domains.
                </span>
                <span className="cardvalue text-start">{totalClient}</span>
                <span className="cardtitel text-start">Clients</span>
              </div>
              <div
                className="card color2"
                onClick={() => handleCardClick("project")}
              >
                <img src={ProjectIcon} alt="" className="icon" />
                <span className="textcontent">
                  Unified access to multi-territories projects for various
                  clients.
                </span>
                <span className="cardvalue2 text-start">{totalProject}</span>
                <span className="cardtitel2 text-start">Projects</span>
              </div>
              
            </div>
            <div className="col1 thirdcol">
              <div className="thirddiv">
                <div
                  className="card color4"
                  onClick={() => handleCardClick("summary")}
                >
                  <img src={SummaryIcon} alt="" className="icon" />
                  <span className="textcontent whiteText">
                    Global portfolio insights for cross-domain projects and
                    clients loyalty programs.
                  </span>
                  <span className="cardtitel2">Portfolio Summary</span>
                </div>
              </div>
              <div className="wideBox">
                <div
                  className="card color5"
                  onClick={() => handleCardClick("contracts")}
                >
                  <div className="d-flex justify-content-between align-items-center">
                    <div>
                      <img
                        src={DocumnetIcon}
                        alt=""
                        className="icon iconother"
                      />
                    </div>

                    <div className="d-flex flex-column">
                      <span className="cardvalue text-end">{totalContact}</span>
                    </div>
                  </div>
                  <span className="cardtitel text-start">
                    Contract Documents
                  </span>
                  {/* <span className="textcontent d-block">
                    Unified asscess to comprehensive Project summary documents,
                    domestic/international client agreements and exclusive
                    loyalty program details for all clients.
                  </span> */}
                </div>
                <div
                  className="card color6"
                  onClick={() => handleCardClick("sources")}
                >
                  <div className="d-flex justify-content-between align-items-center">
                    <div>
                      <img src={CodeIcon} alt="" className="icon iconother" />
                    </div>

                    <div className="d-flex flex-column">
                      <span className="cardvalue text-end">{totalSources}</span>
                    </div>
                  </div>
                  <span className="cardtitel text-start">Source Code</span>
                  {/* <span className="textcontent d-block">
                  Explore the insight from source code of the application.
                  </span> */}
                </div>
              </div>
            </div>
            <span className="logouttag">
              <img src={logoutIcon} alt="logout" />
            </span>
          </div>
        </div>
      </div>
      <Loader show={loading} />
    </>
  );
};

export default Home;
