import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { FaSearch } from 'react-icons/fa';
import "./Search.scss";

const Search = ({ setSearchChnages, orginalData, setsearchdata,searchData, tableDataArray }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [tableData, setTableData] = useState("");

  const searchChnages = (event) => {
    const valueText = event.target.value ;
    console.log('value', valueText)
    setSearchTerm(valueText);
  };

  const location = useLocation();

  // useEffect(() =>{
  //   console.log("location",location.pathname)
  // },[location]);

  useEffect(() => {

    setsearchdata(searchTerm);
    // if(location.pathname == '/summary'){
    //   console.log("if searchTerm",searchTerm)
    //   setsearchdata(searchTerm);
      
    // }
    //  else{
    //   console.log("else searchTerm",searchTerm)
    //       if (searchTerm === "") {
    //         setSearchChnages(orginalData);
    //         searchData(searchTerm);
    //     } else{
    //         const filtered = orginalData.filter((item) => {
    //             return Object.values(item).some(value => 
    //                 value.toString().toLowerCase().includes(searchTerm.toLowerCase())
    //             );
    //           });
    //           setSearchChnages(filtered);
    //        }
    //  }
   
    
  }, [searchTerm]);

  return (
    <div className="searchPanel">
      <input
        type="text"
        placeholder="Search by any keyword"
        value={searchTerm}
        onChange={searchChnages}
        className="inputbox"
      />
      <FaSearch className="search-icon" />
    </div>
  );
};

export default Search;
