import React, { useState, useEffect } from "react";
import { fatchedGetRequest, getURL } from "../ApiService";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import "../Style.css";
import Search from "../common/Search/Search";
import { Paginator } from 'primereact/paginator';


function Client() {
  const [tableData, setTableData] = useState([]);
  const [tableDataOrginal, setTableDataOrginal] = useState([]);
  const [scrollHeight, setScrollHeight] = useState("");
  // const [loading, setLoading] = useState(false);
  const [loadingTable, setLoadingTable] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [totalRow, setTotalRecords] = useState(0);


  useEffect(() => {
    const scrollHeightValue = window.innerHeight - 240;
    // // console.log('height', scrollHeightValue + 'px');
    setScrollHeight(scrollHeightValue + "px");
  }, []);

  const columns = [
    { field: "ClientID", header: "Client ID" },
    { field: "ClientName", header: "Client" },
  ];

  // useEffect(() => {
  //   fetchEmpData();
  // }, []);

  const fetchEmpData = async (page, rows) => {
    try {
      // setLoading(true)
      setLoadingTable(true);
      const responseType = await fatchedGetRequest(`${getURL.getClientDetails}?page=${page}&size=${rows}`);
      console.log("fetchSingleProduct", responseType.data);
      if (responseType.data) {
        setTotalRecords(responseType.total_count);
        setTableData(responseType.data);
        setTableDataOrginal(responseType.data);
      }
      // setLoading(false)
      setLoadingTable(false);
    } catch (error) {
      console.error("Error fetching Data", error.message);
      // setLoading(false)
      setLoadingTable(false);
    } finally {
      setLoadingTable(false);
    }
  };

  useEffect(() => {
    fetchEmpData(currentPage , rowsPerPage);
  }, [currentPage, rowsPerPage]);

  const onPageChange = (event) => {
    const { first, rows } = event;
    console.log("check",event, first, rows)
    const newPage = Math.floor(first / rows) + 1; 
    setCurrentPage(newPage); 
    setRowsPerPage(rows); 
  };


  // const searchChnages = (event) => {
  //   console.log("event", event);
  //   setTableData(event);
  //   setTotalRecords(event.length);
  // };

  // const searchTerm = (event) => {
  //   console.log(" search event", event);
  //   if(event == ""){
  //     fetchEmpData(currentPage , rowsPerPage);
  //   }
  // };

  const searchdata = (event) => {
    console.log("searchdata", event,rowsPerPage);
    
    let timeoutId;
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    timeoutId = setTimeout(() => {
      if(event == []){
        fetchEmpData(currentPage , rowsPerPage);
      }
      else{
        fetchsearchData(event,currentPage, rowsPerPage)
      }
    }, 600);   
  };

  const fetchsearchData = async (event,page, rows) => {
    try {
      // setLoading(true)
      setLoadingTable(true);
      const responseType = await fatchedGetRequest(
        `${getURL.getClientSearch}?q=${event}&page=${page}&size=${rows}`);
      console.log("fetchSingleProduct", responseType,responseType.length);
      if (responseType) {
        setTotalRecords(responseType.length);
        setTableData(responseType);
      }
      // setLoading(false)
      setLoadingTable(false);
    } catch (error) {
      console.error("Error fetching Data", error.message);
      // setLoading(false)
      setLoadingTable(false);
    } finally {
      setLoadingTable(false);
    }
  };


  return (
    <>
      <div className="titleBlock">
        <h4>Client</h4>
        <Search
         setsearchdata ={searchdata}
          // setSearchChnages={searchChnages}
          // searchData={searchTerm}
          // orginalData={tableDataOrginal}
          // tableDataArray={tableData}
        />
      </div>
      <div className="blockPanel">
        <DataTable
          value={tableData}
          tableStyle={{ minWidth: "50rem" }}
          // first={0}
          // rows={50}
          scrollable
          //  scrollHeight="460px"
          columnResizeMode="expand"
          resizableColumns
          showGridlines
          scrollHeight={scrollHeight}
          // paginator
          // rowsPerPageOptions={[50, 100, 150]}
          // paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
          // currentPageReportTemplate="{first} to {last} of {totalRecords}"
          loading={loadingTable}
          className="custom-header-color"
          tooltip="Enter your username"
          tooltipOptions={{ position: "left" }}
        >
          {columns.map((col) => (
            <Column key={col.field} field={col.field} header={col.header} />
          ))}
        </DataTable>

        <Paginator first={(currentPage - 1) * rowsPerPage} rows={rowsPerPage} totalRecords={totalRow} rowsPerPageOptions={[50, 100, 150]} onPageChange={onPageChange} />

      </div>
      {/* <Loader show={loading} /> */}
    </>
  );
}

export default Client;
