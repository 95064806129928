import React from "react";
import ChatBot from "./chatbot/chatbot";

 
function LiveChat( ) {
  return (
     <ChatBot />
  );
}

export default LiveChat;
