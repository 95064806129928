import React, { useState, useEffect } from "react";

import { fatchedGetRequest, getURL } from "../../ApiService";
import "./Search.scss";

const SearchableDropdown = ({ selectedValue, setSelectedValue }) => {
  const [items, setItems] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [loading, setLoading] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const preDefineClients = [
    {
      ClientID: 0,
      ClientName: "All Clients",
    },
    {
      ClientID: 1,
      ClientName: "Axis Bank Limited",
    },
    {
      ClientID: 2,
      ClientName: "Alkem Laboratories Limited",
    },
    {
      ClientID: 3,
      ClientName: "Microsoft Corporation",
    },
  ];

  useEffect(() => {
    setItems(preDefineClients);
  }, []);

  const handleSearch = async (query) => {
    try {
      setLoading(true);
      const response = await fatchedGetRequest(
        `${getURL.getClientSearch}?q=${query}&page=&size=`
      );
      const updatedItems = [
        { ClientID: 0, ClientName: "All Clients" },
        ...response,
      ];
      setItems(updatedItems);
    } catch (error) {
      console.error("Error searching items:", error);
    } finally {
      setLoading(false);
    }
  };
  const handleInputChange = (e) => {
    const query = e.target.value;
    setSearchQuery(query);
    // if (query) {
    //   handleSearch(query);
    // }
  };
  const handleSelect = (item) => {
    setSelectedItem(item);
    setSearchQuery("");
    setDropdownOpen(false);
    setSelectedValue(item);
  };

  useEffect(() => {
    console.log("selectedValue", selectedValue);
    if (selectedValue === null) {
      // setItems([])
      setSearchQuery("");
      setSelectedItem(null);
    }
  }, [selectedValue]);

  return (
    <div className="searchBoxpanel">
      <div
        className="typeingInput selectBoxField"
        onClick={() => setDropdownOpen(!dropdownOpen)}
      >
        {selectedItem ? selectedItem.ClientName : "Select an option"}
      </div>

      {dropdownOpen && (
        <div
          style={{
            position: "absolute",
            top: "100%",
            left: 0,
            width: "100%",
            border: "1px solid #ccc",
            borderRadius: "4px",
            backgroundColor: "white",
            zIndex: 1000,
            maxHeight: "18.75rem",
            overflowY: "auto",
          }}
        >
          {/* <div style={{ padding: "8px", borderBottom: "1px solid #eee" }}>
            <input
              type="text"
              value={searchQuery}
              onChange={handleInputChange}
              placeholder="Search..."
              className="typeingInput"
            />
          </div> */}
          {loading ? (
            <div style={{ padding: "10px", textAlign: "center" }}>
              Loading...
            </div>
          ) : items?.length > 0 ? (
            items?.map((item) => (
              <div
                key={item.ClientID}
                style={{
                  padding: "0.5rem  1rem",
                  cursor: "pointer",
                  borderBottom: "1px solid #f0f0f0",
                }}
                onMouseDown={() => handleSelect(item)}
              >
                {item.ClientName}
              </div>
            ))
          ) : (
            <div style={{ padding: "10px", textAlign: "center" }}>
              No results found
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default SearchableDropdown;
